export function sortObjectKeys<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys) as T;
  }

  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const sorted = {} as T;
  const sortedKeys = Object.keys(obj).sort();

  for (const key of sortedKeys) {
    (sorted as any)[key] = sortObjectKeys((obj as any)[key]);
  }

  return sorted;
}
