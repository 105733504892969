import { getEnv, useMobile } from 'common-nextjs';
import dynamic from 'next/dynamic';
import React from 'react';
import { Message } from 'ui';
import FreestarAd from '~/components/FreestarAd';
import HomepageCmsHero from '~/components/Homepage/HomepageCmsHero';
import BlockSection from '~/components/legos/BlockSection';
import BlogPostGridBlock from '~/components/legos/Blocks/BlogPostGridBlock';
import BrandCollectionBlock from '~/components/legos/Blocks/BrandCollectionBlock';
import BuyingGuideBlock from '~/components/legos/Blocks/BuyingGuideBlock';
import CategorySelections from '~/components/legos/Blocks/CategorySelections';
import CollapsibleButterContent from '~/components/legos/Blocks/CollapsibleButterContent';
import ExploreCollectionBlock from '~/components/legos/Blocks/ExploreCollectionBlock';
import HomeFeaturedCategoriesBlock from '~/components/legos/Blocks/HomeFeaturedCategoriesBlock';
import ItemGridHorizontalScrollBlock from '~/components/legos/Blocks/ItemGridHorizontalScrollBlock';
import ItemGridRowBlock from '~/components/legos/Blocks/ItemGridRowBlock';
import LanderCollectionsLightBlock from '~/components/legos/Blocks/LanderCollectionsLightBlock';
import LanderFeaturedCategoriesBlock from '~/components/legos/Blocks/LanderFeaturedCategoriesBlock';
import LanderHeaderBlock from '~/components/legos/Blocks/LanderHeaderBlock';
import MicroBannerBlock from '~/components/legos/Blocks/MicroBannerBlock';
import PopularModelsBlock from '~/components/legos/Blocks/PopularModelsBlock';
import RecentlyViewedItemBlock from '~/components/legos/Blocks/RecentlyViewedItemBlock';
import ShapedRecommendedItemsGridBlock from '~/components/legos/Blocks/ShapedRecommendedItemsGridBlock';
import ShortHeroBlock from '~/components/legos/Blocks/ShortHeroBlock';
import SingleBlock from '~/components/legos/Blocks/SingleBlock';
import TextParagraphBlock from '~/components/legos/Blocks/TextParagraphBlock';
import TextPillsBlock from '~/components/legos/Blocks/TextPillsBlock';
import TextTitleBlock from '~/components/legos/Blocks/TextTitleBlock';
import UserFeedBlock from '~/components/legos/Blocks/UserFeedBlock';
import BasicTile from '~/components/legos/Tiles/BasicTile';
import HorizontalOnlyUnlimitedTile from '~/components/legos/Tiles/HorizontalOnlyUnlimitedTile';
import UnlimitedTile from '~/components/legos/Tiles/UnlimitedTile';
import UnlimitedTitleTile from '~/components/legos/Tiles/UnlimitedTitleTile';
import { useSession } from '~/contexts/SessionContext';
import { RailsBlock, RailsPageView } from '~/typings/services/rails/tile';
import processBlockTiles from '~/utils/processBlockTiles';

const ResultsGridBlock = dynamic(
  () => import('~/components/Search/ResultsGridBlock'),
);

interface Props {
  block: RailsBlock;
  hasHero: boolean;
  hasTitleBlock: boolean;
  pageView?: RailsPageView;
}

const RenderBlock: React.FC<Props> = ({
  block,
  hasHero,
  hasTitleBlock,
  pageView,
}) => {
  const session = useSession();
  const mobile = useMobile();

  if (!session.loggedIn && block.login_required) {
    if (getEnv() !== 'production') {
      return (
        <div className="container">
          <Message info>
            Block &ldquo;
            <code>{block.title}</code>&rdquo; skipped because login is required
            and you&apos;re not logged in
          </Message>
        </div>
      );
    }
    return null;
  }

  let content: React.ReactNode = null;

  let sectionHeaderType: 'h1' | 'h2' | 'h3' | undefined = undefined;

  if (hasHero && hasTitleBlock) {
    sectionHeaderType = 'h3';
  } else if (hasHero && !hasTitleBlock) {
    sectionHeaderType = 'h2';
  } else if (!hasHero && !hasTitleBlock) {
    sectionHeaderType = 'h1';
  }

  const blockSorted = block.sorted == true;

  switch (block.layout) {
    case 'carousel':
    case 'hero':
      content = (
        <HomepageCmsHero
          block={block}
          loggedIn={session.loggedIn}
          mobile={mobile}
        />
      );
      break;

    case 'lander-header':
      content = (
        <LanderHeaderBlock
          block={block}
          headerType={hasHero ? 'h2' : 'h1'}
          mobile={mobile}
        />
      );
      break;

    case 'text-title':
      content = (
        <TextTitleBlock block={block} headerType={hasHero ? 'h2' : 'h1'} />
      );
      break;

    case 'home-featured-categories':
      content = (
        <HomeFeaturedCategoriesBlock
          block={block}
          headerType={sectionHeaderType}
          mobile={mobile}
        />
      );
      break;

    case 'text-paragraph':
      content = <TextParagraphBlock block={block} />;
      break;

    case 'group-rectangle':
    case 'group-8':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-4">
            {block.tiles?.map(tile => <BasicTile key={tile.id} tile={tile} />)}
          </div>
        </BlockSection>
      );
      break;

    case 'horizontal-scroll-round-icons-with-button':
    case 'group-square':
    case 'group-4':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div className="grid grid-cols-3 gap-2 md:grid-cols-6 md:gap-4">
            {block.tiles?.map(tile => <BasicTile key={tile.id} tile={tile} />)}
          </div>
        </BlockSection>
      );
      break;

    case 'collapsable-content-butter':
      content = (
        <CollapsibleButterContent
          block={block}
          headerType={sectionHeaderType}
        />
      );
      break;

    case 'lander-collections-light':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <LanderCollectionsLightBlock block={block} />
        </BlockSection>
      );
      break;

    case 'explore-collection':
      content = (
        <BlockSection
          block={block}
          headerType={sectionHeaderType}
          showCta={false}
        >
          <ExploreCollectionBlock block={block} />
        </BlockSection>
      );
      break;

    case 'brand-collection':
      content = (
        <BlockSection
          block={block}
          headerType={sectionHeaderType}
          showCta={false}
        >
          <BrandCollectionBlock block={block} />
        </BlockSection>
      );
      break;

    case 'lander-featured-categories':
      content = <LanderFeaturedCategoriesBlock block={block} />;
      break;

    case 'group-unlimited':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div
            className="grid grid-cols-3 gap-2 md:gap-4"
            style={{
              gridAutoFlow: blockSorted ? 'column' : undefined,
              gridTemplateRows: blockSorted
                ? `repeat(${Math.ceil(block.tiles?.length! / 3)}, 1fr)`
                : undefined,
            }}
          >
            {processBlockTiles(block)?.map(tile => (
              <UnlimitedTile key={tile.id} tile={tile} />
            ))}
          </div>
        </BlockSection>
      );
      break;

    case 'group-unlimited-title':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div
            className="grid grid-cols-3 gap-2 md:gap-4"
            style={{
              gridAutoFlow: blockSorted ? 'column' : undefined,
              gridTemplateRows: blockSorted
                ? `repeat(${Math.ceil(block.tiles?.length! / 3)}, 1fr)`
                : undefined,
            }}
          >
            {processBlockTiles(block)?.map(tile => (
              <a key={tile.id} href={tile.destination}>
                <UnlimitedTitleTile title={tile.title} />
              </a>
            ))}
          </div>
        </BlockSection>
      );
      break;

    case 'group-unlimited-1-column-title': {
      const cols = mobile ? 1 : 3;
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div
            className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4"
            style={{
              gridAutoFlow: blockSorted ? 'column' : undefined,
              gridTemplateRows: blockSorted
                ? `repeat(${Math.ceil(block.tiles?.length! / cols)}, 1fr)`
                : undefined,
            }}
          >
            {processBlockTiles(block)?.map(tile => (
              <a key={tile.id} href={tile.destination}>
                <UnlimitedTitleTile title={tile.title} />
              </a>
            ))}
          </div>
        </BlockSection>
      );
      break;
    }

    case 'group-unlimited-1-column-title-image': {
      const cols = mobile ? 1 : 3;
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div
            className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4"
            style={{
              gridAutoFlow: blockSorted ? 'column' : undefined,
              gridTemplateRows: blockSorted
                ? `repeat(${Math.ceil(block.tiles?.length! / cols)}, 1fr)`
                : undefined,
            }}
          >
            {processBlockTiles(block)?.map(tile => (
              <a key={tile.id} href={tile.destination}>
                <HorizontalOnlyUnlimitedTile
                  image={tile.background_image_url}
                  title={tile.title}
                />
              </a>
            ))}
          </div>
        </BlockSection>
      );
      break;
    }

    case 'group-unlimited-2-column-title': {
      const cols = mobile ? 2 : 3;

      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div
            className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4"
            style={{
              gridAutoFlow: blockSorted ? 'column' : undefined,
              gridTemplateRows: blockSorted
                ? `repeat(${Math.ceil(block.tiles?.length! / cols)}, 1fr)`
                : undefined,
            }}
          >
            {processBlockTiles(block)?.map(tile => (
              <a key={tile.id} href={tile.destination}>
                <UnlimitedTitleTile title={tile.title} />
              </a>
            ))}
          </div>
        </BlockSection>
      );
      break;
    }

    case 'group-unlimited-2-column-title-image': {
      const cols = mobile ? 2 : 3;

      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <div
            className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4"
            style={{
              gridAutoFlow: blockSorted ? 'column' : undefined,
              gridTemplateRows: blockSorted
                ? `repeat(${Math.ceil(block.tiles?.length! / cols)}, 1fr)`
                : undefined,
            }}
          >
            {processBlockTiles(block)?.map(tile => (
              <a key={tile.id} href={tile.destination}>
                <HorizontalOnlyUnlimitedTile
                  image={tile.background_image_url}
                  title={tile.title}
                />
              </a>
            ))}
          </div>
        </BlockSection>
      );
      break;
    }

    case 'single':
      content = <SingleBlock block={block} />;
      break;

    case 'micro-banner':
      if (!pageView) {
        break;
      }
      content = <MicroBannerBlock block={block} pageView={pageView} />;
      break;

    case 'item-grid-horizontal-scroll':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <ItemGridHorizontalScrollBlock block={block} />
        </BlockSection>
      );
      break;

    case 'item-grid-1-row':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <ItemGridRowBlock block={block} rows={1} />
        </BlockSection>
      );
      break;

    case 'item-grid-2-rows':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <ItemGridRowBlock block={block} rows={2} />
        </BlockSection>
      );
      break;

    case 'item-grid-3-rows':
      break;

    case 'item-grid-recently-viewed':
      content = (
        <RecentlyViewedItemBlock block={block} headerType={sectionHeaderType} />
      );
      break;

    case 'item-grid-shaped-v1':
      if (!session.loggedIn) {
        break;
      }
      content = (
        <ShapedRecommendedItemsGridBlock
          block={block}
          headerType={sectionHeaderType}
        />
      );
      break;

    case 'group-categories':
      content = (
        <BlockSection block={block} headerType={sectionHeaderType}>
          <CategorySelections />
        </BlockSection>
      );
      break;

    case 'ad-slot':
      content = (
        <div className="container my-4 md:my-8">
          <FreestarAd adSlot={block.title} />
        </div>
      );
      break;

    case 'top-models':
      content = (
        <PopularModelsBlock block={block} headerType={sectionHeaderType} />
      );
      break;

    case 'results':
    case 'results-grid':
      content = <ResultsGridBlock headerType={sectionHeaderType} />;
      break;

    case 'buying-guide':
      content = (
        <div className="container">
          <BuyingGuideBlock block={block} headerType={sectionHeaderType} />
        </div>
      );
      break;

    case 'text-pills':
      content = <TextPillsBlock block={block} headerType={sectionHeaderType} />;
      break;

    case 'short-hero-carousel':
      if (block.tiles && block.tiles.length > 0) {
        content = <ShortHeroBlock block={block} />;
      }
      break;

    case 'blog-post-grid-3':
      content = (
        <BlogPostGridBlock block={block} headerType={sectionHeaderType} />
      );
      break;

    case 'user-feed':
      content = <UserFeedBlock block={block} headerType={sectionHeaderType} />;
      break;

    default:
      if (getEnv() === 'production') {
        content = null;
      } else {
        console.warn("Didn't render unknown block:", block.layout);
        content = (
          <div className="container">
            <Message warning>
              Not setup to handle rendering &ldquo;
              <code>{block.layout}</code>
              &rdquo; layout type.
            </Message>
          </div>
        );
      }
      break;
  }

  if (getEnv() !== 'production' && block.login_required) {
    return (
      <div className="border-primary-500 bg-primary-100 container my-4 rounded border px-0">
        <div className="text-primary-700 mb-4 ml-4 mt-2 font-semibold">
          Login required block
        </div>

        {content}
      </div>
    );
  }

  return content;
};

export default RenderBlock;
