import { QueryClient } from '@tanstack/react-query';
import {
  fetcher,
  getBaseUrl,
  getSessionUsername,
  isLoggedIn,
  makeFetcher,
  Session,
} from 'fetcher-session';
import { fetchShapedRecommendedRailsItems } from '~/api/appDir/shapedAi';
import { fetchFacetItemsNoMeta } from '~/api/items';
import { fetchModelsByPosition } from '~/api/models';
import { getButterPostsByTagForLanders } from '~/lib/buttercms/searchPages/posts';
import { FlattenedFilterState } from '~/typings/services/rails/facets';
import { RailsLander } from '~/typings/services/rails/landers';
import { RailsBlock, RailsPageView } from '~/typings/services/rails/tile';
import { RailsUser } from '~/typings/services/rails/user';

export type PageViewPlatform = 'web' | 'ios' | 'mobile' | 'android';
export const fetchPageView = makeFetcher<RailsPageView>(
  (id: number, platform: PageViewPlatform = 'web', useOrigin = false) => [
    `/v1/page_views/${id}`,
    {
      params: {
        platform,
      },
      baseUrl: useOrigin ? undefined : getBaseUrl('edge'),
    },
  ],
);

export const fetchProductionPageView = makeFetcher<RailsPageView>(
  (id: number, platform: PageViewPlatform = 'web') => [
    `/v1/page_views/${id}`,
    {
      params: {
        platform,
      },
      baseUrl: 'https://api.sidelineswap.com',
    },
  ],
);

type UserFeedUser = Pick<
  RailsUser,
  | 'id'
  | 'uuid'
  | 'username'
  | 'avatar_url'
  | 'following'
  | 'emblems'
  | 'badges'
  | 'feedback'
> & {
  available_count: number;
};

export function fetchUserFeed(categoryId?: number) {
  return fetcher<UserFeedUser[]>('/v1/active_sellers', {
    params: {
      category_id: categoryId,
      page_size: 8,
    },
  });
}

function itemGridRow(block: RailsBlock, mobile: boolean, row: number) {
  return fetchFacetItemsNoMeta({
    ...block.query,
    page_size: mobile ? row * 4 : row * 6,
  });
}

interface PageViewInitialData {
  filterState?: FlattenedFilterState;
  lander?: RailsLander;
}

export const fetchAllPageViewData = (
  pageView: RailsPageView,
  mobile: boolean,
  additionalData: PageViewInitialData = {},
  queryClient: QueryClient,
  session: Session,
) => {
  const promises: Promise<any>[] = [];

  for (let i = 0; i < pageView.blocks.length; i++) {
    const block = pageView.blocks[i];
    if (block.id == null) {
      block.id = i;
    }

    const categoryId =
      Number(
        block.query?.category_id ||
          block.category?.id ||
          additionalData?.filterState?.category?.[0] ||
          additionalData?.lander?.query?.category?.[0] ||
          additionalData?.lander?.category?.id,
      ) || '';

    const brandId =
      Number(
        block.query?.brand_id ||
          additionalData?.lander?.query?.brand?.[0] ||
          additionalData?.filterState?.brand?.[0],
      ) || '';

    switch (block.layout) {
      case 'item-grid-1-row':
        if (queryClient) {
          promises.push(
            queryClient.prefetchQuery(['item-block', block.query, 1], () =>
              itemGridRow(block, mobile, 1),
            ),
          );
        }
        break;

      case 'item-grid-2-rows':
        if (queryClient) {
          promises.push(
            queryClient.prefetchQuery(['item-block', block.query, 2], () =>
              itemGridRow(block, mobile, 2),
            ),
          );
        }
        break;

      case 'item-grid-3-rows':
        if (queryClient) {
          promises.push(
            queryClient.prefetchQuery(['item-block', block.query, 3], () =>
              itemGridRow(block, mobile, 3),
            ),
          );
        }
        break;

      case 'item-grid-horizontal-scroll':
        if (queryClient) {
          promises.push(
            queryClient.prefetchQuery(
              ['horizontal-item-block', block.query],
              () => fetchFacetItemsNoMeta(block.query),
            ),
          );
        }
        break;

      case 'top-models':
        promises.push(
          queryClient.prefetchQuery(['shop-models', categoryId, brandId], () =>
            fetchModelsByPosition(categoryId, brandId, 8),
          ),
        );
        break;

      case 'buying-guide':
        if (queryClient && block.tiles && categoryId != null) {
          for (let j = 0; j < block.tiles.length; j++) {
            const tile = block.tiles[j];
            if (tile.display_type === 'popular_models') {
              promises.push(
                queryClient.prefetchQuery(
                  ['buying-guide-popular-models', tile.id],
                  () =>
                    fetchModelsByPosition(
                      // Why does TypeScript 4.2.4 not get this...
                      categoryId,
                      brandId,
                      10,
                    ),
                ),
              );
            }
          }
        }
        break;

      case 'blog-post-grid-3':
        if (!block.query?.tag_slug) {
          break;
        }
        promises.push(
          queryClient.prefetchQuery(['blog-posts', block.query?.tag_slug], () =>
            getButterPostsByTagForLanders(block.query?.tag_slug),
          ),
        );
        break;

      case 'user-feed':
        promises.push(
          queryClient.prefetchQuery(['user-feed', block.category?.id], () =>
            fetchUserFeed(block.category?.id),
          ),
        );
        break;

      case 'item-grid-shaped-v1':
        const username = getSessionUsername(session);
        if (!isLoggedIn(session) || !username) {
          break;
        }

        promises.push(
          queryClient.prefetchQuery(['shaped-recommended-items'], () =>
            fetchShapedRecommendedRailsItems(username),
          ),
        );
        break;

      default:
        promises.push(new Promise(resolve => resolve(null)));
        continue;
    }
  }

  return Promise.all(promises);
};
